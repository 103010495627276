import React from 'react';


const Homemenu = () => {
  return (
    <div className="d-flex flex-column justify-content-center w-100 h-100 homemenu">
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className='homemenu_container_btn'>
                <a href="/marspets/login" className="card_homemenu">
                    <div className="logo"><img src="https://infopro54.ru/wp-content/uploads/2021/06/Mars-Petcare-lockup-RGB.png" alt="" /></div>
                </a>
                <a href="/efes/login" className="card_homemenu">
                    <div className="logo"><img src="https://companieslogo.com/img/orig/AEFES.IS-2d1e0fd5.png?t=1720244490" alt="" /></div>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Homemenu;