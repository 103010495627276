import React from "react";
import "../Styles/employed.scss";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { Employee, FiredEmployee as FiredEmpl } from "../models.ts";
import { Employee as Empl } from "../models.ts";
import Slider from "@mui/material/Slider";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import Button from "@mui/material/Button";
import { fetchEmployee, fetchFiredEmployee } from "../api_control.ts";
import { BarChart } from "@mui/x-charts/BarChart";
import axiosInstance from "../../axiosConfig.js";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

dayjs.locale("ru");

const Employed = () => {
  const [dataFiredEmployee, setDataFiredEmployee] = useState<FiredEmpl[]>([]);
  const [dataActEmployee, setDataActEmployee] = useState<Employee[]>([]);
  const [experienceRange, setExperienceRange] = useState([0, 60]);
  const [filteredEmpl, setFilteredEmpl] = useState<FiredEmpl[]>([]);
  const [filteredActEmpl, setFilteredActEmpl] = useState<Empl[]>([]);
  const [startD, setStartD] = useState<Dayjs | null>(null);
  const [endD, setEndD] = useState<Dayjs | null>(null);
  const [data, setData] = useState([
    { name: "Актуальные сотрудники", value: 0 },
    { name: "Уволенные сотрудники", value: 0 },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataEmpl = await fetchEmployee();
        setDataActEmployee(dataEmpl);
        const dataFiredEmpl = await fetchFiredEmployee();
        setDataFiredEmployee(dataFiredEmpl);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const COLORS = ["#02B2AF", "#FF0000"];

  const fetchEmployeeCounts = async (date) => {
    try {
      const response = await axiosInstance(`/employees/counts?date=${date}`);
      const result = response.data;
      setData([
        { name: "Актуальные сотрудники", value: result.activeEmployees },
        { name: "Уволенные сотрудники", value: result.firedEmployees },
      ]);
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0]; // текущая дата
    fetchEmployeeCounts(currentDate);
  }, []);

  const getTargetDate = () => {
    return endD ? endD.format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
  };

  useEffect(() => {
    const targetDate = getTargetDate();
    fetchEmployeeCounts(targetDate);
  }, [endD]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontSize: "24px", fontWeight: "bold" }}
      >
        {`${data[index].value}`}
      </text>
    );
  };

  const calculateWorkExperience = (startDate, endDate) => {
    const now = new Date(endDate);
    const start = new Date(startDate);

    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();
    let days = now.getDate() - start.getDate();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (days < 0) {
      months--;
      const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      days += lastMonth.getDate();
    }

    const totalMonths = years * 12 + months;

    let experience = "";
    if (years > 0) {
      experience += `${years} ${
        years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
      }`;
    }

    if (months > 0) {
      if (experience) experience += " ";
      experience += `${months} ${
        months === 1 ? "месяц" : months > 1 && months < 5 ? "месяца" : "месяцев"
      }`;
    }

    if (years === 0 && months === 0 && days >= 0) {
      if (days === 0) {
        experience = "менее дня";
      } else {
        experience = `${days} ${
          days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
        }`;
      }
    }

    return { formattedExperience: experience.trim(), totalMonths };
  };

  const handleRangeChange = (event, newValue) => {
    setExperienceRange(newValue);
  };

  const processData = (firedEmployees, actEmployee) => {
    const hires = {};
    const fires = {};

    const incrementCount = (obj, date) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [day, month, year] = date.split(".");
      const key = `${year}-${month}`;

      if (!obj[key]) {
        obj[key] = 0;
      }
      obj[key]++;
    };

    actEmployee.forEach((employee) => {
      incrementCount(hires, employee.dateemployment);
    });

    firedEmployees.forEach((employee) => {
      incrementCount(fires, employee.datefired);
    });

    return { hires, fires };
  };

  // Вспомогательная функция для парсинга даты в формате "дд.мм.гггг"
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(".");
    return new Date(`${year}-${month}-${day}`);
  };

  // Фильтрация актуальных сотрудников по дате трудоустройства, стажу и сортировка по дате
  const filterActiveEmployees = () => {
    const filteredActSt = dataActEmployee.filter((employee) => {
      if (!employee.dateemployment) return false;

      const startDate = parseDate(employee.dateemployment);
      const endDate = new Date();
      const { totalMonths } = calculateWorkExperience(startDate, endDate);

      // Фильтрация по диапазону стажа
      return totalMonths >= experienceRange[0] && totalMonths <= experienceRange[1];
    });

    const filteredActDate = filteredActSt
      .filter((employee) => {
        if (!employee.dateemployment) return false;

        const employmentDate = parseDate(employee.dateemployment);

        // Фильтрация по выбранному диапазону дат
        if (startD && employmentDate < startD.toDate()) return false;
        if (endD && employmentDate > endD.toDate()) return false;

        return true;
      })
      // Сортировка по дате трудоустройства по возрастанию
      .sort((a, b) => parseDate(a.dateemployment).getTime() - parseDate(b.dateemployment).getTime());

    setFilteredActEmpl(filteredActDate);
  };

  // Фильтрация уволенных сотрудников по дате увольнения, стажу и сортировка по дате
  const filterFiredEmployees = () => {
    const filteredFirSt = dataFiredEmployee.filter((employee) => {
      if (!employee.dateemployment || !employee.datefired) return false;

      const startDate = parseDate(employee.dateemployment);
      const endDate = parseDate(employee.datefired);
      const { totalMonths } = calculateWorkExperience(startDate, endDate);

      // Фильтрация по диапазону стажа
      return totalMonths >= experienceRange[0] && totalMonths <= experienceRange[1];
    });

    const filteredFirDate = filteredFirSt
      .filter((employee) => {
        if (!employee.datefired) return false;

        const firedDate = parseDate(employee.datefired);

        // Фильтрация по выбранному диапазону дат
        if (startD && firedDate < startD.toDate()) return false;
        if (endD && firedDate > endD.toDate()) return false;

        return true;
      })
      // Сортировка по дате увольнения по возрастанию
      .sort((a, b) => parseDate(a.datefired).getTime() - parseDate(b.datefired).getTime());

    setFilteredEmpl(filteredFirDate);
  };

  useEffect(() => {
    if (dataFiredEmployee) {
      filterFiredEmployees();
    }
    if (dataActEmployee) {
      filterActiveEmployees();
    }
  }, [dataFiredEmployee, dataActEmployee, experienceRange, startD, endD]);

  const marks = [
    { value: 0, label: "0 лет" },
    { value: 12, label: "1 год" },
    { value: 24, label: "2 года" },
    { value: 36, label: "3 года" },
    { value: 48, label: "4 года" },
    { value: 60, label: "5 лет" },
  ];

  const { hires, fires } = processData(filteredEmpl, filteredActEmpl);

  const labels = [
    ...new Set([...Object.keys(hires), ...Object.keys(fires)]),
  ].sort();

  const hireData = labels.map((label) => ({
    x: new Date(`${label}-01`).getTime(),
    y: hires[label] || 0,
  }));

  const fireData = labels.map((label) => ({
    x: new Date(`${label}-01`).getTime(),
    y: fires[label] || 0,
  }));

  const formatDate = (date) => {
    try {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "long",
      }).format(new Date(date));
    } catch (error) {
      console.error("Invalid date value:", date);
      return "";
    }
  };

  // Актуальные
  const columnsAct: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "shift",
      headerName: "Смена",
      type: "string",
      width: 110,
      editable: false,
      valueGetter: (value, row) => {
        if (row.shift) {
          return row.shift.nameshift;
        }
      },
    },
    {
      field: "mainPost",
      headerName: "Должность",
      width: 200,
      editable: false,
      valueGetter: (value, row) => {
        if (row.mainPost) {
          return row.mainPost.namepost;
        }
      },
    },
    {
      field: "dateemployment",
      headerName: "Дата трудоуст.",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "typeemployment",
      headerName: "Тип",
      width: 80,
      editable: false,
    },
    {
      field: "dateedst",
      headerName: "Стаж",
      width: 100,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date();
        const { formattedExperience } = calculateWorkExperience(
          startDate,
          endDate
        );
        return formattedExperience;
      },
    },
  ];

  // Уволенные
  const columnsFrd: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 40,
    //   valueGetter: (value, row) => {
    //     return row.id;
    //   },
    // },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
      valueGetter: (value, row) => {
        return row.fio;
      },
    },
    {
      field: "post",
      headerName: "Должность",
      width: 250,
      type: "string",
      editable: false,
      valueGetter: (value, row) => {
        if (row.mainPost) {
          return row.mainPost.namepost;
        }
      },
    },
    {
      field: "phone",
      headerName: "Номер телефона",
      width: 130,
      editable: false,
    },
    {
      field: "dateemployment",
      headerName: "Дата трудоуст.",
      width: 130,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "datefired",
      headerName: "Дата увольнения",
      width: 140,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datefired) {
          return null;
        }
        const [day, month, year] = row.datefired.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "reason",
      headerName: "Причина увольнения",
      width: 300,
      type: "string",
      editable: false,
    },
    {
      field: "dateedst",
      headerName: "Стаж",
      width: 100,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.dateemployment || !row.datefired) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const [day1, month1, year1] = row.datefired.split(".");
        const endDate = new Date(`${year1}-${month1}-${day1}`);
        const { formattedExperience } = calculateWorkExperience(
          startDate,
          endDate
        );
        return formattedExperience;
      },
    },
  ];

  const [showFirstTable, setShowFirstTable] = useState(true);

  const handleToggle = () => {
    setShowFirstTable(!showFirstTable);
  };

  const handleReset = () => {
    setStartD(null);
    setEndD(null);
  };

  return (
    <div className="employed_container">
      <div className="time_container">
        <div className="time_title">Отобразить данные в промежутке от</div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={startD}
              onChange={(date) => setStartD(date)}
              label="дд.мм.гггг"
            />
          </DemoContainer>
        </LocalizationProvider>
        <div className="time_title">до</div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={endD}
              onChange={(date2) => setEndD(date2)}
              label="дд.мм.гггг"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Button
          variant="contained"
          onClick={handleReset}
          style={{
            width: "120px",
            height: "56px",
            marginTop: "8px",
            marginLeft: "20px",
          }}
        >
          Сбросить
        </Button>
      </div>
      <div
        style={{
          width: "500px",
          marginBottom: 10,
          marginTop: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="st_title">Стаж: </div>
        <Slider
          value={experienceRange}
          onChange={handleRangeChange}
          valueLabelDisplay="auto"
          min={0}
          max={60}
          marks={marks}
        />
      </div>
      <div className="graph_container">
        <BarChart
          xAxis={[
            {
              id: "Years",
              data: labels,
              scaleType: "band",
              valueFormatter: (label) => label,
            },
          ]}
          series={[
            {
              id: "Act",
              label: "Прием на работу",
              data: hireData.map((d) => d.y || 0),
            },
            {
              id: "Fired",
              label: "Увольнение",
              data: fireData.map((d) => d.y || 0),
              color: "red",
            },
          ]}
          width={600}
          height={400}
        />
        {/* <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%" 
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel} 
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              /> 
            ))}
          </Pie>
          <Tooltip />
        </PieChart> */}
      </div>
      <Button
        variant="contained"
        onClick={handleToggle}
        style={{ marginBottom: "20px" }}
      >
        {showFirstTable
          ? "Переключиться на уволенных сотрудников"
          : "Переключиться на актуальных сотрудников"}
      </Button>
      <div className="table_container_employed">
        {showFirstTable ? (
          <div className="table_employed">
            <DataGrid
              rows={filteredActEmpl ?? []}
              columns={columnsAct}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 12,
                  },
                },
              }}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              pageSizeOptions={[12]}
              disableRowSelectionOnClick
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
            />
          </div>
        ) : (
          <div className="table_employed">
            <DataGrid
              rows={filteredEmpl ?? []}
              columns={columnsFrd}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Employed;
