import React, { useCallback, useEffect, useState } from "react";
import "../Styles/employee.scss";
import ModalUpd from "../Components/modal_update.js";
import ModalAdd from "../Components/modal_add.tsx";
import "../Styles/tableArea.scss";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/notification.tsx";
import Slider from "@mui/material/Slider";
import { Employee as Employ } from "../models.ts";
import axiosInstance from "../../axiosConfig.js";
import { formatDate } from "../models.ts";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";


export interface Employees {
  id: number;
  fio: string;
  gender: string;
  datebirthday: string | null;
  phone: string;
  shift: Shift | null;
  mainPost: Post | null;
  dateemployment: string | null;
  datelmk: string | null;
  dateflg: string | null;
  dateadsm: string | null;
  datesiz: string | null;
  dateinst: string | null;
  status: boolean | null;
  datechange: string | null;
}

export interface Shift {
  id: number;
  nameshift: string;
}

interface Post {
  id: number;
  namepost: string;
}

interface Vacation {
  employeeid: Employees;
  datestart: string | null;
  dateend: string | null;
}

const Employee = () => {
  const [dataEmployee, setDataEmployee] = useState<Employees[]>([]);
  const [experienceRange, setExperienceRange] = useState([0, 60]);
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const [showModalFired, setShowModalFired] = useState(false);
  const [showModalAddVacation, setShowModalAddVacation] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState(null);
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
  const [reason, setReason] = useState(null);
  const [datefired, setDateFired] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [datestartvacation, setDateStartVacation] = useState("");
  const [dateendvacation, setDateEndVacation] = useState("");

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleAddSuccess = () => {
    fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      const employeesResponse = await axiosInstance.get("/employees");
      const employeeData = employeesResponse.data
        .filter((employee) => employee.status === true)
        .map((employee) => ({
          ...employee,
          datebirthday: formatDate(employee.datebirthday),
          dateemployment: formatDate(employee.dateemployment),
          datelmk: formatDate(employee.datelmk),
          dateflg: formatDate(employee.dateflg),
          dateadsm: formatDate(employee.dateadsm),
          datesiz: formatDate(employee.datesiz),
          dateinst: formatDate(employee.dateinst),
          datechange: formatDate(employee.datechange),
        }));
      setDataEmployee(employeeData);
    } catch (error) {
      console.error(
        "Ошибка получения данных о сотрудниках, сменах и должностей:",
        error
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (dataEmployee) {
      const filtered = dataEmployee
        .filter((employee: Employees) => {
          if (!employee.dateemployment) {
            return false; // Не возвращаем null, просто пропускаем
          }
          const [day, month, year] = employee.dateemployment.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const { totalMonths } = calculateWorkExperience(startDate);
          return (
            totalMonths >= experienceRange[0] &&
            totalMonths <= experienceRange[1]
          );
        })
        .map((employee: Employees) => ({
          ...employee,
          dateonjob: null, // Добавляем недостающие свойства, если они нужны
          datefire: null,
          dateelectrosecurity: null,
          numelectrosecurity: 0,
        }));

      setFilteredEmpl(filtered);
    }
  }, [dataEmployee, experienceRange]);

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  // Функция для добавления отпуска
  const handleAddVacation = (id, fio) => {
    setShowModalAddVacation(true);
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
  };

  // Функция для увольнения сотрудника
  const handleFired = (id, fio) => {
    setShowModalFired(true);
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
  };

  // Функция увольнения
  const confirmFired = async () => {
    if (!selectedEmployeeId || !datefired || !reason) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    try {
      const params = new URLSearchParams();
      params.append("employeeid", selectedEmployeeId);
      params.append("datefired", datefired);
      params.append("reason", reason);

      const fireEmployeePromise = axiosInstance.put(
        `/employees/${selectedEmployeeId}/fire`
      );
      const addReasonPromise = axiosInstance.post(
        `/fired?${params.toString()}`
      );

      await Promise.all([fireEmployeePromise, addReasonPromise]);

      setShowModalFired(false);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Сотрудник уволен",
        subtitle2: <div></div>,
        isVisible: true,
      });
    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при увольнении сотрудника",
        subtitle2: <div></div>,
        isVisible: true,
      });
      console.error(
        "Ошибка при увольнении сотрудника и добавлении причины",
        error
      );
    }
  };

  // Функция добавления отпуска
  const confirmVacation = async () => {
    if (!selectedEmployeeId || !dateendvacation || !datestartvacation) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    const formattedStart = new Date(datestartvacation);
    const formattedEnd = new Date(dateendvacation);

    if (formattedEnd < formattedStart) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Дата окончания не может быть раньше даты начала",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    try {
      const formattedStartISO = formattedStart.toISOString();
      const formattedEndISO = formattedEnd.toISOString();

      const VacData = {
        employeeid: selectedEmployeeId,
        datestart: formattedStartISO,
        dateend: formattedEndISO,
      };

      await axiosInstance.post<Vacation>(
        "/vacations/vacations_create",
        VacData
      );

      setShowModalAddVacation(false);
      setDateStartVacation("");
      setDateEndVacation("");
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Отпуск добавлен",
        subtitle2: <div></div>,
        isVisible: true,
      });
    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при добавлении отпуска",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  // Функция для обработки нажатия на кнопку "Редактировать"
  const handleEdit = (id) => {
    setSelectedEmployeeId(id);
    setIsModalVisibleUpd(true);
  };

  const handleModalClose = () => {
    setIsModalVisibleUpd(false);
  };

  const handleModalAddClose = () => {
    setIsModalVisibleAdd(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    // {
    //   field: "gender",
    //   headerName: "Пол",
    //   width: 40,
    //   editable: false,
    // },
    {
      field: "years",
      headerName: "Возраст",
      width: 40,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.datebirthday) {
          return null;
        }
        const [day, month, year] = row.datebirthday.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const now = new Date();
        let years = now.getFullYear() - startDate.getFullYear();
        return years;
      },
    },
    {
      field: "datebirthday",
      headerName: "Дата рождения",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datebirthday) {
          return null;
        }
        const [day, month, year] = row.datebirthday.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "phone",
      headerName: "Номер телефона",
      width: 130,
      editable: false,
    },
    {
      field: "shift",
      headerName: "Смена",
      type: "string",
      width: 110,
      editable: false,
      valueGetter: (value, row) => {
        if (row.shift != null){
          return row.shift.nameshift;
        }
      },
    },
    {
      field: "mainPost",
      headerName: "Должность",
      width: 200,
      editable: false,
      valueGetter: (value, row) => {
        if (row.mainPost != null){
          return row.mainPost.namepost;
        }
      },
    },
    {
      field: "dateemployment",
      headerName: "Дата трудоуст.",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "typeemployment",
      headerName: "Тип",
      width: 80,
      editable: false,
    },
    // {
    //   field: "dateinst",
    //   headerName: "Вводн. инстр.",
    //   width: 120,
    //   editable: false,
    //   type: "date",
    //   valueGetter: (value, row) => {
    //     if (!row.dateinst) {
    //       return null;
    //     }
    //     const [day, month, year] = row.dateinst.split(".");
    //     return new Date(`${year}-${month}-${day}`);
    //   },
    // },
    // {
    //   field: "datechange",
    //   headerName: "Смена должности",
    //   width: 130,
    //   editable: false,
    //   type: "date",
    //   valueGetter: (value, row) => {
    //     if (!row.datechange) {
    //       return null;
    //     }
    //     const [day, month, year] = row.datechange.split(".");
    //     return new Date(`${year}-${month}-${day}`);
    //   },
    //   valueFormatter: (value: any, row) => {
    //     if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
    //       return "Не менялась";
    //     }

    //     const day = value.getDate().toString().padStart(2, "0");
    //     const month = (value.getMonth() + 1).toString().padStart(2, "0");
    //     const year = value.getFullYear();

    //     return `${day}.${month}.${year}`;
    //   },
    // },
    {
      field: "dateedst",
      headerName: "Стаж",
      width: 100,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const { formattedExperience } = calculateWorkExperience(startDate);
        return formattedExperience;
      },
    },
    {
      field: "button",
      headerName: "Действия",
      width: 130,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleFired(params.row.id, params.row.fio)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => handleAddVacation(params.row.id, params.row.fio)}
          >
            <BeachAccessIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const calculateWorkExperience = (startDate) => {
    const now = new Date();
    const start = new Date(startDate);

    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();
    let days = now.getDate() - start.getDate();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (days < 0) {
      months--;
      const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      days += lastMonth.getDate();
    }

    const totalMonths = years * 12 + months;

    let experience = "";
    if (years > 0) {
      experience += `${years} ${
        years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
      }`;
    }

    if (months > 0) {
      if (experience) experience += " ";
      experience += `${months} ${
        months === 1 ? "месяц" : months > 1 && months < 5 ? "месяца" : "месяцев"
      }`;
    }

    if (years === 0 && months === 0 && days >= 0) {
      experience =
        days === 0
          ? "менее дня"
          : `${days} ${
              days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
            }`;
    }

    return { formattedExperience: experience.trim(), totalMonths };
  };

  const handleRangeChange = (event, newValue) => {
    setExperienceRange(newValue);
  };

  const [filteredEmpl, setFilteredEmpl] = useState<Employ[]>([]);

  const marks = [
    { value: 0, label: "0 лет" },
    { value: 12, label: "1 год" },
    { value: 24, label: "2 года" },
    { value: 36, label: "3 года" },
    { value: 48, label: "4 года" },
    { value: 60, label: "5 лет" },
  ];


  return (
    <div className="main_cont">
      <div className="employee_cont1">
        <Button
          variant="contained"
          onClick={() => setIsModalVisibleAdd(true)}
          style={{ marginTop: "20px" }}
        >
          Добавить сотрудника
        </Button>
        {/* <div
          style={{
            width: "500px",
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="st_title">Стаж: </div>
          <Slider
            value={experienceRange}
            onChange={handleRangeChange}
            valueLabelDisplay="auto"
            min={0}
            max={60}
            marks={marks}
          />
        </div> */}
        <DataGrid
          style={{
            marginTop: 20,
          }}
          rows={filteredEmpl ?? []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          pageSizeOptions={[12]}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
      {/* Модальное окно для добавления информации о сотруднике */}
      {isModalVisibleAdd && (
        <ModalAdd
          closeModalAdd={handleModalAddClose}
          onAddSuccess={handleAddSuccess}
        />
      )}
      {/* Модальное окно для подтверждения увольнения сотрудника */}
      {showModalFired && (
        <div className="modal_fired">
          <div className="modal_cont_fired">
            <h3>Вы уверены, что хотите уволить этого сотрудника?</h3>
            <label>ФИО: {selectedEmployeeFio}</label>

            <div className="dat">
              <label htmlFor="datefired">Дата увольнения:</label>
              <input
                type="date"
                id="datefired"
                name="datefired"
                value={datefired}
                onChange={(e) => setDateFired(e.target.value)}
              />
            </div>

            <label htmlFor="reason">Причина увольнения:</label>
            <textarea
              name="reason"
              placeholder="Напишите причину увольнения"
              onChange={handleReasonChange}
              rows={4}
            />

            <div className="modal_act_fir">
              <button onClick={confirmFired}>Уволить</button>
              <button onClick={() => setShowModalFired(false)}>Отмена</button>
            </div>
          </div>
        </div>
      )}
      {/* Модальное окно для редактирования информации о сотруднике */}
      {isModalVisibleUpd && (
        <ModalUpd
          selectedEmployeeId={selectedEmployeeId}
          closeModalUpd={handleModalClose}
          onUpdateSuccess={handleUpdateSuccess}
        />
      )}
      {/* Модальное окно для добавления отпуска */}
      {showModalAddVacation && (
        <div className="modal_vacation">
          <div className="modal_cont_vacation">
            <h3>Добавление отпуска</h3>
            <label>ФИО: {selectedEmployeeFio}</label>

            <div className="dat_vac">
              <label htmlFor="datestartvacation">Дата начала:</label>
              <input
                type="date"
                id="datestartvacation"
                name="datestartvacation"
                value={datestartvacation}
                onChange={(e) => setDateStartVacation(e.target.value)}
              />
            </div>

            <div className="dat_vac">
              <label htmlFor="dateendvacation">Дата окончания:</label>
              <input
                type="date"
                id="dateendvacation"
                name="dateendvacation"
                value={dateendvacation}
                onChange={(e) => setDateEndVacation(e.target.value)}
              />
            </div>

            <div className="modal_act_vacation">
              <button onClick={confirmVacation}>Добавить</button>
              <button
                onClick={() => {
                  setShowModalAddVacation(false);
                  setDateStartVacation("");
                  setDateEndVacation("");
                }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};

export default Employee;
