import '../../Styles/control.scss';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import "dayjs/locale/ru";
import {
  Employee,
  formatDate2
} from "../../models.ts";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Button} from "@mui/material";
import {
  fetchEmployee,
  fetchEmployeesWithLMKExpired,
  fetchEmployeesWithLMKExpiring,
} from "../../api_control.ts";
import { useDispatch} from "react-redux";
import { setExpiringLMKs, setExpiredLMKs } from "../../Global/controlSlice.ts";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../../Components/notification.tsx";
import axiosInstance from '../../../axiosConfig.js';

 
const Control = () => {
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
  const [LMKExpiring, setLMKExpiring] = useState<Employee[]>([]);
  const [LMKExpired, setLMKExpired] = useState<Employee[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [filteredExpiring, setFilteredExpiring] = useState<Employee[]>([]);
  const [filteredExpired, setFilteredExpired] = useState<Employee[]>([]);

  const [isFiltered, setIsFiltered] = useState(false);

  const [showFirstTable, setShowFirstTable] = useState(true);
  const [colorBtn, setColorBtn] = useState("btn3");

  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  const [befDateLMK, setBefDateLMK] = useState("");
  const [selectedDateLMK, setSelectedDateLMK] = useState("");
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const handleToggle = () => {
    setShowFirstTable(!showFirstTable);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const rowCountExpired1 = filteredExpired.length;
    const rowCountExpiring1 = filteredExpiring.length;
    dispatch(setExpiringLMKs(rowCountExpiring1));
    dispatch(setExpiredLMKs(rowCountExpired1));
  }, [dispatch, filteredExpired.length, filteredExpiring.length]);

  const fetchData = async () => {
    try {
      const data1 = await fetchEmployeesWithLMKExpiring();
      setLMKExpiring(data1);
      const data2 = await fetchEmployeesWithLMKExpired();
      setLMKExpired(data2);
      const dataEmpl = await fetchEmployee();
      setDataEmployee(dataEmpl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterDataForMonth = useCallback(
    (date: Date) => {
      const month = date.getMonth();

      const expiringFiltered = LMKExpiring.filter((employee) => {
        if (!employee.datelmk) {
          return false;
        }
        const [day, monthStr, year] = employee.datelmk.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered = LMKExpired.filter((employee) => {
        if (!employee.datelmk) {
          return false;
        }
        const [day, monthStr, year] = employee.datelmk.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      setFilteredExpiring(expiringFiltered);
      setFilteredExpired(expiredFiltered);
    },
    [LMKExpiring, LMKExpired]
  );

  const handleResetFilter = () => {
    setColorBtn("btn3");
    setIsFiltered(false);
  };

  useEffect(() => {
    if (isFiltered && (LMKExpiring.length > 0 || LMKExpired.length > 0)) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredExpiring(LMKExpiring);
      setFilteredExpired(LMKExpired);
    }
  }, [selectedMonth, LMKExpiring, LMKExpired, isFiltered, filterDataForMonth]);

  const handleMonthChange = (direction: "next" | "prev") => {
    if (direction === "prev") {
      setColorBtn("btn1");
    } else {
      setColorBtn("btn2");
    }
    setIsFiltered(true);
    setSelectedMonth(() => {
      const newMonth = new Date();
      if (direction === "next") {
        newMonth.setMonth(newMonth.getMonth() + 1);
      }
      return newMonth;
    });
  };

  const rowCountExpired = filteredExpired.length;
  const rowCountExpiring = filteredExpiring.length;
  const rowCountAll = dataEmployee ? dataEmployee.length : 0;

  const procExpired = Math.round((rowCountExpired / rowCountAll) * 100);
  const procExpiring = Math.round((rowCountExpiring / rowCountAll) * 100);

  const { value: value1 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpired,
  });

  const { value: value2 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiring,
  });

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "shift",
      headerName: "Смена",
      width: 110,
      type: "string",
      editable: false,
      valueGetter: (value, row) => {
        if (row.shift) {
          return row.shift.nameshift;
        }
      },
    },
    {
      field: "datelmk",
      headerName: "Дата прохождения ЛМК",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datelmk) {
          return null;
        }
        const [day, month, year] = row.datelmk.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "datelmk2",
      headerName: "Дата срока окончания ЛМК",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datelmk) {
          return null;
        }
        const [day, month, year] = row.datelmk.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row.id, params.row.fio, params.row.datelmk)}>
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // Редактирование
  const handleEdit = (id, fio, date) => {
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    const [day, monthStr, year] = date.split(".");
    const lmkDate = new Date(`${year}-${monthStr}-${day}`);
    setBefDateLMK(formatDate2(lmkDate));
    setSelectedDateLMK(formatDate2(lmkDate));
    setIsModalVisibleUpd(true);
  };

  const confirmUpdLMK = async () => {
    if (!selectedDateLMK) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Введите дату",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    if (selectedDateLMK === befDateLMK){
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    try{
      const formattedDateLmk = new Date(selectedDateLMK).toISOString();
      const lmkData = {
        datelmk: formattedDateLmk,
      };
      await axiosInstance.put(`/employees/${selectedEmployeeId}`, lmkData);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные ЛМК обновлены!",
        subtitle2: <div></div>,
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  useEffect(() => {
    if (!showFirstTable) {
      setColorBtn("btn3");
      setIsFiltered(false);
    }
  }, [showFirstTable]);

  return (
    <div>
      <div className="control_container">
        <div className="navbar_control">
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">ЛМК в течении месяца</div>
              <div className="num_cntrl">{rowCountExpiring}</div>
            </div>
            <CircularProgress
              determinate
              value={value2 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ffaa00",
              }}
            >
              <Typography>{value2}%</Typography>
            </CircularProgress>
          </div>
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">ЛМК просрочена</div>
              <div className="num_cntrl" style={{ color: "#ad1100" }}>
                {rowCountExpired}
              </div>
            </div>
            <CircularProgress
              determinate
              value={value1 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ad1100",
              }}
            >
              <Typography>{value1}%</Typography>
            </CircularProgress>
          </div>
        </div>
        <div className="btn_m">
          <Button
            onClick={() => handleMonthChange("prev")}
            variant="contained"
            style={{
              backgroundColor: colorBtn === "btn1" ? "#D51B6C" : "",
            }}
            disabled={!showFirstTable}
          >
            Текущий месяц
          </Button>
          <Button
            onClick={() => handleMonthChange("next")}
            variant="contained"
            style={{
              backgroundColor: colorBtn === "btn2" ? "#D51B6C" : "",
            }}
            disabled={!showFirstTable}
          >
            Следующий месяц
          </Button>
          <Button
            onClick={handleResetFilter}
            variant="contained"
            style={{
              backgroundColor: colorBtn === "btn3" ? "#D51B6C" : "",
            }}
          >
            Все месяца
          </Button>
        </div>
        <Button
          variant="contained"
          onClick={handleToggle}
          style={{ marginBottom: "20px" }}
        >
          {showFirstTable
            ? "Переключиться на данные с просроченной ЛМК"
            : "Переключиться на ближайшие ЛМК"}
        </Button>
        <div className="table_cont_control">
          {showFirstTable ? (
            <div>
              <h3 style={{ textAlign: "center", color: "#ffaa00" }}>
                {colorBtn === "btn1"
                  ? "ЛМК в этом месяце"
                  : colorBtn === "btn2"
                  ? "ЛМК в следующем месяце"
                  : "ЛМК в течении 2х месяцев"}
              </h3>
              <div className="table_employed">
                <DataGrid
                  rows={filteredExpiring ?? []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          ) : (
            <div>
              <h3 style={{ textAlign: "center", color: "#ad1100" }}>
                ЛМК просрочена
              </h3>
              <div className="table_employed">
                <DataGrid
                  rows={filteredExpired ?? []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalVisibleUpd && (
        <div className="modal_upd_lmk">
          <div className="modal_cont_upd_lmk">
            <label style={{ marginBottom: "10px" }}>
              ФИО: {selectedEmployeeFio}
            </label>

            <label style={{ marginBottom: "10px" }}>
              Дата прохождения ЛМК:
            </label>
            <input
              type="date"
              value={selectedDateLMK}
              onChange={(e) => setSelectedDateLMK(e.target.value)}
            />

            <div
              style={{
                display: "flex",
                marginTop: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button onClick={confirmUpdLMK}>Редактировать</button>
              <button onClick={() => setIsModalVisibleUpd(false)}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          subtitle2={notification.subtitle2}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
};

export default Control;
