import React, { useState, useEffect, useCallback } from "react";
import "../Styles/print.scss";
import Select, { SingleValue } from "react-select";
import axiosInstance from "../../axiosConfig.js";
import { Employee } from "../models.ts";
import Button from "@mui/material/Button";

interface EmployeeOption {
  value: number;
  label: string;
}

const Print = () => {
    const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] =
      useState<SingleValue<EmployeeOption>>(null);

    const handleEmployeeChange = (
        selectedOption: SingleValue<EmployeeOption>
        ) => {
        setSelectedEmployee(selectedOption);
    };

    const employeeOptions: EmployeeOption[] = dataEmployee.map(
        (employee) => ({
            value: employee.id,
            label: employee.fio,
        })
    );

    const fetchData = useCallback(async () => {
      try {
        const response = await axiosInstance.get("/employees");
        const data = response.data
          .filter((employee) => employee.status === true)
          .map((employee) => ({
            ...employee,
          }));
        setDataEmployee(data);
      } catch (error) {
        console.error("Error:", error);
      }
    }, []);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const handlePrintDoc = async (documentType, employeeid) => {
      try {
        const response = await axiosInstance.get(
          `/documents/${documentType}/${employeeid}`,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Ошибка при получении PDF:", error);
      }
    };


    return (
      <div className="print_container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label htmlFor="employee-dropdown" style={{ marginBottom: "10px" }}>
            Сотрудник:
          </label>
          <Select
            id="employee-dropdown"
            value={selectedEmployee}
            options={employeeOptions}
            onChange={handleEmployeeChange}
            placeholder="Выберите сотрудника"
            styles={{
              container: (provided) => ({
                ...provided,
                marginBottom: "10px",
                width: "350px",
              }),
              control: (provided) => ({
                ...provided,
                height: "20px",
              }),
              valueContainer: (provided) => ({
                ...provided,
                height: "20px",
                padding: "0 8px",
              }),
              input: (provided) => ({
                ...provided,
                height: "20px",
                margin: "0",
                padding: "0",
              }),
              singleValue: (provided) => ({
                ...provided,
                height: "20px",
                padding: "0",
                margin: "0",
              }),
              placeholder: (provided) => ({
                ...provided,
                height: "20px",
                margin: "0",
              }),
            }}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <div className="info_print_cont">
            <h3>Проверьте, все ли документы напечатаны:</h3>
            <p>-карточка СИЗ</p>
            <p>-распоряжение о стажировке</p>
            <p>-протокол ОТиТБ</p>
            <p>-личная карточка</p>
            <p>-протокол проверки знаний в электроустановках</p>
          </div>
          <div className="btn_cont_printf">
            <Button
              variant="contained"
              onClick={() => handlePrintDoc("siz", selectedEmployee?.value)}
              style={{ marginTop: "10px" }}
            >
              карточка СИЗ
            </Button>
            <Button
              variant="contained"
              onClick={() => handlePrintDoc("staz", selectedEmployee?.value)}
              style={{ marginTop: "10px" }}
            >
              распоряжение о стажировке
            </Button>
            <Button
              variant="contained"
              onClick={() => handlePrintDoc("otitb", selectedEmployee?.value)}
              style={{ marginTop: "10px" }}
            >
              протокол ОТиТБ
            </Button>
            <Button
              variant="contained"
              onClick={() => handlePrintDoc("lna", selectedEmployee?.value)}
              style={{ marginTop: "10px" }}
            >
              личная карточка
            </Button>
            {/* <Button
              variant="contained"
              onClick={() =>
                handlePrintDoc("ydelectro", selectedEmployee?.value)
              }
              style={{ marginTop: "10px" }}
            >
              удостоверение по ЭБ
            </Button> */}
            <Button
              variant="contained"
              onClick={() =>
                handlePrintDoc("protelectro", selectedEmployee?.value)
              }
              style={{ marginTop: "10px" }}
            >
              протокол проверки знаний в электроустановках
            </Button>
          </div>
        </div>
      </div>
    );
}
 
export default Print;