import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate
  } from "react-router-dom";
  import MarsNavbar from './MarsPets/Components/navbar.tsx';
  import MarsLogin from './MarsPets/Pages/login';
  import Employee from './MarsPets/Pages/table_employee.tsx';
  import TableSiz from './MarsPets/Pages/table_siz.tsx';
  import Lmk from './MarsPets/Pages/table_lmk.tsx';
  import Control from './MarsPets/Pages/DateControl/control_lmk.tsx';
  import ControlFlg from './MarsPets/Pages/DateControl/control_flg.tsx';
  import ControlAdsm from "./MarsPets/Pages/DateControl/control_adsm.tsx";
  import ControlSiz from "./MarsPets/Pages/DateControl/control_siz.tsx";
  import Vacations from "./MarsPets/Pages/table_vacations.tsx";
  import HistoryPosts from './MarsPets/Pages/history_posts.tsx';
  import Employed from './MarsPets/Pages/table_employed.tsx';
  import AreaTable from './MarsPets/Pages/table_area.tsx';
  import Briefings from './MarsPets/Pages/DateControl/control_briefings.tsx';
  import Print from './MarsPets/Pages/print.tsx';
  import Homepage from './MarsPets/Pages/homepage.tsx';
  import TableTrainings from './MarsPets/Pages/table_trainings.tsx';
  import { useEffect } from 'react';

  
  function MarsRoutes() {
    const location = useLocation();
    const navigate = useNavigate();
    const hideNavbar = location.pathname === '/marspets/login';
  
    // Получаем статус аутентификации из localStorage
    const isAuthenticated = !!localStorage.getItem('token');
  
    useEffect(() => {
      if (!isAuthenticated && location.pathname !== '/marspets/login') {
        navigate('/marspets/login');
      } else if (isAuthenticated && location.pathname === '/marspets/login') {
        navigate('/marspets/');
      }
    }, [location.pathname, navigate, isAuthenticated]);
  
    return (
      <div style={{ display: "flex", width: "100%" }}>
        {!hideNavbar && <MarsNavbar />}
        <Routes>
          <Route path="/login" element={<MarsLogin />} />
          {isAuthenticated ? (
            <>
              <Route path="/employee" element={<Employee />} />
              <Route path="/siz" element={<TableSiz />} />
              <Route path="/lmk" element={<Lmk />} />
              <Route path="/area" element={<TableTrainings />} />
              <Route path="/control" element={<Control />} />
              <Route path="/control_flg" element={<ControlFlg />} />
              <Route path="/control_adsm" element={<ControlAdsm />} />
              <Route path="/control_siz" element={<ControlSiz />} />
              <Route path="/vacations" element={<Vacations />} />
              <Route path="/history_ports" element={<HistoryPosts />} />
              <Route path="/employed" element={<Employed />} />
              <Route path="/area_table" element={<AreaTable />} />
              <Route path="/control_briefings" element={<Briefings />} />
              <Route path="/print" element={<Print />} />
              <Route path="/" element={<Homepage />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/marspets/login" />} />
          )}
        </Routes>
      </div>
    );
  }
  
  export default MarsRoutes;