import React from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import axiosInstance from "../../axiosConfig.js";
import { formatDate } from "../models.ts";
import { useEffect, useState } from "react";

const HistoryPosts = () => {
    const [posts, setPosts] = useState([]);

    const fetchEmployeePosts = async () => {
      try {
        const response = await axiosInstance.get(
          `/employee_post`
        );
        return response.data;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchEmployeePosts();
          setPosts(data);
        } catch (error) {
          console.error("Error:", error);
        }
      };
      fetchData();
    }, []);

    const columns: GridColDef[] = [
      {
        field: "id",
        headerName: "ID",
        width: 40,
        valueGetter: (value, row) => {
          return row.employee.id;
        },
      },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          return row.employee.fio;
        },
      },
      {
        field: "post",
        headerName: "Должность",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          return row.post.namepost;
        },
      },
      {
        field: "datechange",
        headerName: "Дата присвоения",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (!row.datechange) {
            return null;
          }
          const newDate = formatDate(row.datechange);
          return newDate;
        },
      },
      {
        field: "status",
        headerName: "Статус",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.status) {
            return "Текущая";
          } else {
            return "Прошлая";
          }
        },
      },
    ];

    return (
      <div className="history_posts_container" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
        <div>
          <DataGrid
            rows={posts ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[12]}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </div>
      </div>
    );
}
 
export default HistoryPosts;