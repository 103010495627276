import { formatDate } from "./models.ts";
import axiosInstance from "../axiosConfig.js";
import { Employee } from "./models.ts";


export const fetchEmployee = async () => {
  try {
    const response = await axiosInstance.get("employees");
    return response.data
      .filter((employee) => employee.status === true)
      .map((employee) => ({
        ...employee,
        datebirthday: formatDate(employee.datebirthday),
        dateemployment: formatDate(employee.dateemployment),
        datelmk: formatDate(employee.datelmk),
        dateflg: formatDate(employee.dateflg),
        dateadsm: formatDate(employee.dateadsm),
        datesiz: formatDate(employee.datesiz),
        dateinst: formatDate(employee.dateinst),
        datechange: formatDate(employee.datechange),
      }));
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchNumShift = async () => {
  try {
    const response = await axiosInstance.get("employees/count_by_shift");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchStats = async () => {
  try {
    const response = await axiosInstance.get("employees/tenure_stats");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};


export const fetchFiredEmployee = async () => {
  try {
    const response = await axiosInstance.get("fired");
    return response.data.map((firedEmployee) => ({
      ...firedEmployee,
      datefired: formatDate(firedEmployee.datefired),
      dateemployment: formatDate(firedEmployee.dateemployment),
    }));
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchEmployeesWithLMKExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/lmk-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      datelmk: formatDate(employee.datelmk),
    }));
  } catch (error) {
    console.error("Error fetching employees with LMK expiring:", error);
    throw error;
  }
};

export const fetchEmployeesWithLMKExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/lmk-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      datelmk: formatDate(employee.datelmk),
    }));
  } catch (error) {
    console.error("Error fetching employees with LMK expired:", error);
    throw error;
  }
};

export const fetchEmployeesWithSIZExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/siz-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      datesiz: formatDate(employee.datesiz),
    }));
  } catch (error) {
    console.error("Error fetching employees with SIZ expiring:", error);
    throw error;
  }
};

export const fetchEmployeesWithSIZExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/siz-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      datesiz: formatDate(employee.datesiz),
    }));
  } catch (error) {
    console.error("Error fetching employees with SIZ expired:", error);
    throw error;
  }
};

// ФЛГ
export const fetchEmployeesWithFLGExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/flg-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateflg: formatDate(employee.dateflg),
    }));
  } catch (error) {
    console.error("Error");
    throw error;
  }
};

export const fetchEmployeesWithFLGExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/flg-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateflg: formatDate(employee.dateflg),
    }));
  } catch (error) {
    console.error("Error");
    throw error;
  }
};

// АДСМ
export const fetchEmployeesWithADSMExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/adsm-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateadsm: formatDate(employee.dateadsm),
    }));
  } catch (error) {
    console.error("Error");
    throw error;
  }
};

export const fetchEmployeesWithADSMExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/adsm-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateadsm: formatDate(employee.dateadsm),
    }));
  } catch (error) {
    console.error("Error");
    throw error;
  }
};

// На рабочем месте 
export const fetchEmployeesWithOnJobExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/onJob-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateonjob: formatDate(employee.dateonjob),
    }));
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeesWithOnJobExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/onJob-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateonjob: formatDate(employee.dateonjob),
    }));
  } catch (error) {
    throw error;
  }
};

// Пожарная безопасность 
export const fetchEmployeesWithFireExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/fire-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      datefire: formatDate(employee.datefire),
    }));
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeesWithFireExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/fire-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      datefire: formatDate(employee.datefire),
    }));
  } catch (error) {
    throw error;
  }
};

// Электробезопасность
export const fetchEmployeesWithElectroExpiring = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/electrosecurity-expiring"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateelectrosecurity: formatDate(employee.dateelectrosecurity),
    }));
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeesWithElectroExpired = async () => {
  try {
    const response = await axiosInstance.get<Employee[]>(
      "employees/electrosecurity-expired"
    );
    return response.data.map((employee) => ({
      ...employee,
      dateelectrosecurity: formatDate(employee.dateelectrosecurity),
    }));
  } catch (error) {
    throw error;
  }
};
