import './MarsPets/Styles/main.scss';
import { Provider } from 'react-redux';
import MarsRoutes from './MarsRoutes.js';
import EfesRoutes from './EfesRoutes.js';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from './store.ts';
import Homemenu from './Homemenu.tsx';

function App() {
  //

  return (
    <Provider store={store}>
      <Router>
        <Routes>
            <Route path="/" element={<Homemenu />} />
            <Route path="/marspets/*" element={<MarsRoutes />} />
            <Route path="/efes/*" element={<EfesRoutes />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;