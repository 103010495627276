import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../axiosConfig.js";
import "../Styles/lmk.scss";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import Button from "@mui/material/Button";
import Select, {SingleValue} from "react-select";
import { formatDate } from "../models.ts";

interface EmployeeOption {
  value: number;
  label: string;
}

interface Employee {
  id: number;
  fio: string;
  datelmk: string;
  dateflg: string;
  dateadsm: string;
}

const Lmk = () => {
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
  const [selectedDateLmk, setSelectedDateLmk] = useState("");
  const [selectedDateFlg, setSelectedDateFlg] = useState("");
  const [selectedDateAdsm, setSelectedDateAdsm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<SingleValue<EmployeeOption>>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/employees/lmk_employee");
      const data = response.data.map((employee) => ({
        ...employee,
        datelmk: formatDate(employee.datelmk),
        dateflg: formatDate(employee.dateflg),
        dateadsm: formatDate(employee.dateadsm),
      }));
      setDataEmployee(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []); 

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDateForPicker = (dateStr) => {
    const [day, month, year] = dateStr.split(".");
    return `${year}-${month}-${day}`;
  };


  const confirm = () => {
    // Check if a selected employee exists
    if (!selectedEmployee) {
      return;
    }

    // Helper function to validate and format dates
    const formatDate = (date) => {
      const parsedDate = new Date(date);
      // Check if the parsed date is valid
      if (isNaN(parsedDate.getTime())) {
        console.error("Invalid date:", date);
        return null; // or handle as needed (e.g., default date)
      }
      return parsedDate.toISOString();
    };

    // Format the dates with validation
    const formattedDateLmk = formatDate(selectedDateLmk);
    const formattedDateFlg = formatDate(selectedDateFlg);
    const formattedDateAdsm = formatDate(selectedDateAdsm);

    // Create the lmkData object, filtering out any null dates
    const lmkData = {
      datelmk: formattedDateLmk,
      dateflg: formattedDateFlg,
      dateadsm: formattedDateAdsm,
    };

    // Log the selected employee and lmkData
    console.log(selectedEmployee);
    console.log(lmkData);

    // Send the request only if there's at least one valid date
    if (formattedDateLmk || formattedDateFlg || formattedDateAdsm) {
      axiosInstance
        .put(`/employees/${selectedEmployee.value}`, lmkData)
        .then(() => {
          setShowModal(false);
          fetchData();
        })
        .catch((error) => {
          console.error("Error updating employee:", error);
        });
    } else {
      console.warn("No valid dates to update.");
    }
  };


  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "datelmk",
      headerName: "ЛМК",
      width: 150,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datelmk) {
          return null;
        }
        const [day, month, year] = row.datelmk.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      valueFormatter: (value: any, row) => {
        if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
          return "Нет данных";
        }

        const day = value.getDate().toString().padStart(2, "0");
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
      },
    },
    {
      field: "dateflg",
      headerName: "ФЛГ",
      width: 150,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateflg) {
          return null;
        }
        const [day, month, year] = row.dateflg.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      valueFormatter: (value: any, row) => {
        if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
          return "Нет данных";
        }
        const day = value.getDate().toString().padStart(2, "0");
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
      },
    },
    {
      field: "dateadsm",
      headerName: "АДСМ",
      width: 150,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateadsm) {
          return null;
        }
        const [day, month, year] = row.dateadsm.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      valueFormatter: (value: any, row) => {
        if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
          return "Нет данных";
        }
        const day = value.getDate().toString().padStart(2, "0");
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
      },
    },
  ];

  const handleEmployeeChange = (
    selectedOption: SingleValue<EmployeeOption>
  ) => {
    setSelectedEmployee(selectedOption);

    if (selectedOption) {
      const selectedEmployeeData = dataEmployee.find(
        (employee) => employee.id === selectedOption.value
      );

      if (selectedEmployeeData) {
        if (selectedEmployeeData.datelmk) {
          setSelectedDateLmk(formatDateForPicker(selectedEmployeeData.datelmk));
        }

        if (selectedEmployeeData.dateflg) {
          setSelectedDateFlg(formatDateForPicker(selectedEmployeeData.dateflg));
        }

        if (selectedEmployeeData.dateadsm) {
          setSelectedDateAdsm(
            formatDateForPicker(selectedEmployeeData.dateadsm)
          );
        }
      }
    }
  };


  const employeeOptions: EmployeeOption[] = dataEmployee.map((employee) => ({
    value: employee.id,
    label: employee.fio,
  }));

  const isSaveDisabled = !(
    selectedEmployee &&
    selectedDateLmk &&
    selectedDateFlg &&
    selectedDateAdsm 
  );
  

  return (
    <div className="lmk_cont">
      <div className="table_cont4">
        <Button
          variant="contained"
          onClick={() => setShowModal(true)}
          style={{ marginTop: "20px" }}
        >
          Добавить/обновить данные ЛМК
        </Button>
        <DataGrid
          rows={dataEmployee}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          pageSizeOptions={[12]}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          style={{ marginTop: "20px" }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
      {/* Модальное окно для добавления */}
      {showModal && (
        <div className="modalTableLmk">
          <div className="modal-contentLmk">
            <h3>Добавление информации ЛМК</h3>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                htmlFor="employee-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Сотрудник:
              </label>
              <Select
                id="employee-dropdown"
                value={selectedEmployee}
                options={employeeOptions}
                onChange={handleEmployeeChange}
                placeholder="Выберите сотрудника"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginBottom: "10px",
                    width: "190px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    height: "18px",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    height: "18px",
                    padding: "0 8px",
                  }),
                  input: (provided) => ({
                    ...provided,
                    height: "18px",
                    margin: "0",
                    padding: "0",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "18px",
                    padding: "0",
                    margin: "0",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    height: "18px",
                    margin: "0",
                  }),
                }}
              />
            </div>

            <label htmlFor="datelmk">Дата прохождения ЛМК:</label>
            <input
              type="date"
              id="datelmk"
              name="datelmk"
              value={selectedDateLmk}
              onChange={(e) => setSelectedDateLmk(e.target.value)}
            />

            <label htmlFor="dateflg">Дата прохождения ФЛГ:</label>
            <input
              type="date"
              id="dateflg"
              name="dateflg"
              value={selectedDateFlg}
              onChange={(e) => setSelectedDateFlg(e.target.value)}
            />

            <label htmlFor="dateadsm">Дата прививки АДСМ:</label>
            <input
              type="date"
              id="dateadsm"
              name="dateadsm"
              value={selectedDateAdsm}
              onChange={(e) => setSelectedDateAdsm(e.target.value)}
            />

            <div className="modal-actionsLmk">
              <button onClick={confirm}>
                Добавить
              </button>
              <button onClick={() => setShowModal(false)}>Отмена</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lmk;
