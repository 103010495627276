export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const formatDate2 = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export interface EmployNex {
  fio: string;
  gender: string;
  datebirthday: string | null;
  phone: string;
  shift: Shift | null;
  mainPost: Post | null;
  dateemployment: string | null;
}

export interface Employee {
  id: number;
  fio: string;
  gender: string;
  datebirthday: string | null;
  phone: string;
  shift: Shift | null;
  mainPost: Post | null;
  dateemployment: string | null;
  datelmk: string | null;
  dateflg: string | null;
  dateadsm: string | null;
  datesiz: string | null;
  dateinst: string | null;
  status: boolean | null;
  datechange: string | null;
  dateonjob: string | null;
  datefire: string | null;
  dateelectrosecurity: string | null;
  numelectrosecurity: number;
}

export interface FiredEmployee {
  id: number;
  fio: string;
  shift: Shift | null;
  mainPost: Post | null;
  datefired: string;
  dateemployment: string | null;
  reason: string;
}

export interface Vacation {
  id: number;
  fio: string;
  mainarea: string;
  shift: Shift;
  startdate: string;
  enddate: string;
  numday: number;
}

export interface GroupedVacation {
  fio: string;
  vacations: Vacation[]; 
  shift: Shift;
}


export interface Shift {
  id: number;
  nameshift: string;
}

export interface Post {
  id: number;
  namepost: string;
}
