import React from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig.js";
import "../Styles/vacations.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/notification.tsx";
import { Vacation, GroupedVacation } from "../models.ts";
import { formatDate } from "../models.ts";
import { Button } from "@mui/material";

const Vacations = () => {
  const [dataVacations, setDataVacations] = useState<Vacation[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedVacationId, setSelectedVacationId] = useState("");
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const [selectedShift, setSelectedShift] = useState("");
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedMainarea, setSelectedMainarea] = useState("");
  const [mainareaOptions, setMainareaOptions] = useState([]);

  const fetchVacations = async () => {
    try {
      const response = await axiosInstance.get<Vacation[]>(
        "vacations/vacations_get"
      );
      return response.data.map((vacation) => ({
        ...vacation,
        startdate: formatDate(vacation.startdate),
        enddate: formatDate(vacation.enddate),
      }));
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchVacations();
        setDataVacations(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "fio",
      headerName: "ФИО",
      width: 200,
      editable: false,
    },
    {
      field: "mainarea",
      headerName: "Основной участок",
      width: 200,
      editable: false,
    },
    {
      field: "shift",
      headerName: "Смена",
      width: 200,
      editable: false,
      valueGetter: (value, row) => {
        return row.shift.nameshift;
      },
    },
    {
      field: "datestart",
      headerName: "Дата начала",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.startdate) {
          return null;
        }
        const [day, month, year] = row.startdate.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      filterable: false,
      sortable: false,
    },
    {
      field: "dateend",
      headerName: "Дата окончания",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.enddate) {
          return null;
        }
        const [day, month, year] = row.enddate.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      filterable: false,
      sortable: false,
    },
    {
      field: "numday",
      headerName: "Дни",
      width: 60,
      editable: false,
    },
    {
      field: "button",
      headerName: "Действия",
      width: 80,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleDeleteVacation(params.row.id, params.row.fio)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDeleteVacation = (id, fio) => {
    setShowModalDelete(true);
    setSelectedVacationId(id);
    setSelectedEmployeeFio(fio);
  };

  const confirmDeleteVacation = async () => {
    try {
      await axiosInstance.delete(
        `/vacations/vacations_del/${selectedVacationId}`
      );

      setShowModalDelete(false);
      const data = await fetchVacations();
      setDataVacations(data);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Отпуск удален",
        subtitle2: <div></div>,
        isVisible: true,
      });
    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при удалении отпуска",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const isDateInMonth = (date: string, month: number, year: number) => {
    const [day, monthStr, yearStr] = date.split('.');
    const dateObj = new Date(Number(yearStr), Number(monthStr) - 1, Number(day));
    return dateObj.getMonth() === month && dateObj.getFullYear() === year;
  };

  const orderedShifts = ["Красная", "Оранжевая", "Желтая", "Зеленая", "Декрет", "Отдел rnd", "Петцентр", "Подработчик", "Подработчик"]; 

  const getFilteredVacations = () => {
    return dataVacations
      .filter(
        (vacation) =>
          (isDateInMonth(vacation.startdate, selectedMonth, selectedYear) ||
            isDateInMonth(vacation.enddate, selectedMonth, selectedYear)) &&
          (!selectedMainarea || vacation.mainarea === selectedMainarea) &&
          (!selectedShift || vacation.shift.nameshift === selectedShift)
      )
      .sort((a, b) => orderedShifts.indexOf(a.shift.nameshift) - orderedShifts.indexOf(b.shift.nameshift));
  };


  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInSelectedMonth = getDaysInMonth(selectedYear, selectedMonth);
  const filteredVacations = getFilteredVacations();

  const mainareaClassMap: { [key: string]: string } = {
    "Вторичная упаковка": "mainarea-1",
    "Мониторинг": "mainarea-2",
    "Паучмейкер": "mainarea-3",
    "Переборка паллет с гп и упаковкой": "mainarea-4",
    "Петцентр": "mainarea-5",
    "СГП": "mainarea-6",
    "Стерилизация": "mainarea-7",
    "ТТ9": "mainarea-8",
    "Холодный склад. Бункерная зона": "mainarea-9"
  };

  const shiftClassMap: { [key: string]: string } = {
    "Зеленая": "shift-green",
    "Оранжевая": "shift-orange",
    "Красная": "shift-red",
    "Желтая": "shift-yellow",
    "Декрет": "shift-dec",
    "Отдел rnd": "shift-rnd",
    "Петцентр": "shift-pet",
    "Подработчик": "shift-pod",
    "СВО": "shift-svo",
  };

  const renderEmployeeName = (vacation: GroupedVacation) => {
    const shiftClass = shiftClassMap[vacation.shift.nameshift] || "default-shift";
    return <td className={`employee-name ${shiftClass}`}>{vacation.fio}</td>;
  };

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  useEffect(() => {
    axiosInstance
      .get("/shifts")
      .then((response) => {
        setShiftOptions(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axiosInstance
      .get("/areas")
      .then((response) => {
        setMainareaOptions(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSbrs = () => {
    setSelectedMainarea("");
    setSelectedShift("");
  };

  const getUniqueEmployeeVacations = (): GroupedVacation[] => {
    const groupedVacations = dataVacations.reduce((acc, vacation) => {
      const employeeKey = vacation.fio;
      if (!acc[employeeKey]) {
        acc[employeeKey] = [vacation];
      } else {
        acc[employeeKey].push(vacation);
      }
  
      return acc;
    }, {} as Record<string, Vacation[]>);
  
    return Object.values(groupedVacations).map((vacations) => ({
      fio: vacations[0].fio,
      vacations: vacations,
      shift: vacations[0].shift,
    }));
  };

  const filteredVacations2 = getUniqueEmployeeVacations()
  .filter((group) => {
    return group.vacations.some((vacation) =>
      (isDateInMonth(vacation.startdate, selectedMonth, selectedYear) ||
        isDateInMonth(vacation.enddate, selectedMonth, selectedYear)) &&
      (!selectedMainarea || vacation.mainarea === selectedMainarea) &&
      (!selectedShift || vacation.shift.nameshift === selectedShift)
    );
  })
  .sort((a, b) => {
    const shiftAIndex = orderedShifts.indexOf(a.shift.nameshift);
    const shiftBIndex = orderedShifts.indexOf(b.shift.nameshift);
    return shiftAIndex - shiftBIndex;
  });

  
  const renderVacationCells = (vacation, dayIndex) => {
    const currentDay = new Date(selectedYear, selectedMonth, dayIndex + 1);
  
    for (const period of vacation.vacations) {
      const [startDay, startMonth, startYear] = period.startdate.split(".").map(Number);
      const [endDay, endMonth, endYear] = period.enddate.split(".").map(Number);
  
      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = new Date(endYear, endMonth - 1, endDay);
  
      if (currentDay >= startDate && currentDay <= endDate) {
        const areaClass = mainareaClassMap[vacation.mainarea] || "default-area";
        return <td key={dayIndex} className={`vacation ${areaClass}`}></td>;
      }
    }
    return <td key={dayIndex}></td>;
  };
  
  return (
    <div className="vacations_cont">
      <DataGrid
        rows={filteredVacations ?? []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 12,
            },
          },
        }}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        pageSizeOptions={[12]}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
      />
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <label>
          Месяц:
          <select
            value={selectedMonth}
            style={{ marginLeft: "10px" }}
            onChange={handleMonthChange}
          >
            {[
              "Январь",
              "Февраль",
              "Март",
              "Апрель",
              "Май",
              "Июнь",
              "Июль",
              "Август",
              "Сентябрь",
              "Октябрь",
              "Ноябрь",
              "Декабрь",
            ].map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </label>
        <label>
          Год:
          <select
            value={selectedYear}
            style={{ marginLeft: "10px" }}
            onChange={handleYearChange}
          >
            {[2023, 2024, 2025].map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label htmlFor="shift-dropdown" style={{ marginBottom: "10px" }}>
          Смена:
        </label>
        <select
          id="shift-dropdown"
          value={selectedShift}
          style={{ width: "225px", marginBottom: "10px" }}
          onChange={(e) => setSelectedShift(e.target.value)}
        >
          <option value="" disabled>
            Выберите смену
          </option>
          {shiftOptions.map((option: any) => (
            <option key={option.id} value={option.nameshift}>
              {option.nameshift}
            </option>
          ))}
        </select>

        <label
          htmlFor="shift-dropdown"
          style={{ marginBottom: "10px", marginLeft: "15px" }}
        >
          Основной участок:
        </label>
        <select
          id="shift-dropdown"
          value={selectedMainarea}
          style={{ width: "225px", marginBottom: "10px" }}
          onChange={(e) => setSelectedMainarea(e.target.value)}
        >
          <option value="" disabled>
            Выберите участок
          </option>
          {mainareaOptions.map((option: any) => (
            <option key={option.id} value={option.namearea}>
              {option.namearea}
            </option>
          ))}
        </select>
        <Button
          variant="contained"
          onClick={handleSbrs}
          style={{ marginLeft: "15px" }}
        >
          Сбросить
        </Button>
      </div>
      <div className="table_vacation">
        <table>
          <thead>
            <tr>
              <th>Сотрудник</th>
              {Array.from({ length: daysInSelectedMonth }, (_, index) => (
                <th key={index}>{index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredVacations2.map((vacation) => (
              <tr>
                {renderEmployeeName(vacation)}
                {Array.from({ length: daysInSelectedMonth }, (_, index) =>
                  renderVacationCells(vacation, index)
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModalDelete && (
        <div className="modal_delete_vacation">
          <div className="modal_cont_delete_vacation">
            <h3>Вы уверены, что хотите удалить данные о отпуске сотрудника?</h3>
            <label>ФИО: {selectedEmployeeFio}</label>

            <div className="modal_act_fir">
              <button onClick={confirmDeleteVacation}>Удалить</button>
              <button onClick={() => setShowModalDelete(false)}>Отмена</button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          subtitle2={notification.subtitle2}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
};

export default Vacations;
