import React from "react";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig.js";
import { Shift } from "../models.ts";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";

interface EmployeeArea {
  id: number;
  fio: string;
  phone: string;
  shift: Shift | null;
  mainarea: Area | null;
  areas: Area[];
}

interface Area {
  namearea: string;
}

const AreaTable = () => {
    const [dataAreaTable, setDataAreaTable] = useState<EmployeeArea[]>([]);
    
    const fetchEmployeeAreas = async () => {
        try {
            const response = await axiosInstance.get<EmployeeArea[]>(
              "employees/areas_employee"
            );
            return response.data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchEmployeeAreas();
          setDataAreaTable(data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, []);

    const columns: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
      },
      {
        field: "phone",
        headerName: "Номер телефона",
        width: 250,
        type: "string",
        editable: false,
      },
      {
        field: "shift",
        headerName: "Смена",
        type: "string",
        width: 110,
        editable: false,
        valueGetter: (value, row) => {
          if (row.shift) {
            return row.shift.nameshift;
          }
        },
      },
      {
        field: "mainarea",
        headerName: "Основной участок",
        width: 200,
        editable: false,
        valueGetter: (value, row) => {
          if (!row.mainarea) {
            return "Нет данных";
          }
          return row.mainarea.namearea;
        },
      },
      {
        field: "areas",
        headerName: "Участки",
        width: 520,
        editable: false,
        valueGetter: (value, row) => {
          const areas = row.areas;
          return areas && areas.length > 0
            ? areas.map((area) => area.namearea).join(", ")
            : "Нет данных";
        },
      },
    ];

    return (
      <div className="table_area_container" style={{width:"100%"}}>
        <div className="table_con_area" style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center", marginTop: "20px"}}>
          <DataGrid
            rows={dataAreaTable ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{ toolbar: { showQuickFilter: true } }}
          />
        </div>
      </div>
    );
}
 
export default AreaTable;