import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import EfesEmployee from "./Efes/Pages/Employee.tsx";
import EfesNavbar from './Efes/Components/Navbar.tsx';
import EfesLogin from './Efes/Pages/Login.tsx';
import './MarsPets/Styles/login.scss';
  
function EfesRoutes() {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <a href="/" className="button-34">🠔 К выбору объекта</a>
      {/* {!isAuthenticated && <EfesNavbar />}
      <Routes>
        <Route path="/efes/login" element={<EfesLogin />} />
        {isAuthenticated ? (
          <>
            <Route path="/efes/employee" element={<EfesEmployee />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/efes/login" replace />} />
        )}
      </Routes> */}
    </div>
  );
}
  
export default EfesRoutes;