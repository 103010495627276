import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import "../Styles/employee.scss";
import axios2 from '../../axiosConfig.js';
import Notification from "../Components/notification.tsx";
import DateChangeModal from './dateChangeModal.js';

const ModalUpd = ({ selectedEmployeeId, closeModalUpd, onUpdateSuccess }) => {
  const [employeeData, setEmployeeData] = useState(null);
  const [fio, setFio] = useState("");
  const [gender, setGender] = useState("");
  const [datebirthday, setDateBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [shift, setShift] = useState("");
  const [post, setPost] = useState("");
  const [electro, setElectro] = useState("");
  const [typeEmployment, setTypeEmployment] = useState("");
  const [dateemployment, setDateEmployment] = useState("");
  const [height, setHeight] = useState("");
  const [clothingsize, setClothingSize] = useState("");
  const [shoesize, setShoeSize] = useState("");
  const [headdress, setHeaddress] = useState("");
  const [shiftOptions, setShiftOptions] = useState([]);
  const [postOptions, setPostOptions] = useState([]);
  const [sizId, setSizId] = useState([]);
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    isVisible: false,
  });
  const [initialEmpData, setInitialEmpData] = useState(null);
  const [initialSizData, setInitialSizData] = useState(null);
  const [isDateModalVisible, setIsDateModalVisible] = useState(false);
  const [changedFields, setChangedFields] = useState({});

  useEffect(() => {
    axios2
      .get(`/employees/${selectedEmployeeId}`)
      .then((response) => {
        const employee = response.data;
        setInitialEmpData(employee);
        setEmployeeData(employee);
        setFio(employee.fio);
        setGender(employee.gender);
        setDateBirthday(formatDate(employee.datebirthday));
        setPhone(employee.phone);
        setShift(employee.shift.id);
        setPost(employee.mainPost.id);
        setDateEmployment(formatDate(employee.dateemployment));
      })
      .catch((error) => {
        console.error(
          "Ошибка при получении данных о сотруднике для редактирования:",
          error
        );
      });
    axios2
      .get(`/siz/employee/${selectedEmployeeId}`)
      .then((response) => {
        const siz = response.data;
        setInitialSizData(siz);
        setSizId(siz.Id);
        setHeight(siz.height);
        setClothingSize(siz.clothingsize);
        setShoeSize(siz.shoesize);
        setHeaddress(siz.headdress);
        setElectro(siz.employee.numelectrosecurity);
        setTypeEmployment(siz.employee.typeemployment);
      })
      .catch((error) => {
        console.error(
          "Ошибка при получении данных о сотруднике для редактирования:",
          error
        );
      });

    axios2
      .get("/shifts")
      .then((response) => setShiftOptions(response.data))
      .catch((error) => console.error("Error fetching shift options:", error));

    axios2
      .get("/posts")
      .then((response) => setPostOptions(response.data))
      .catch((error) => console.error("Error fetching post options:", error));
  }, [selectedEmployeeId]);

  const heightOptions = ["158-164", "170-176", "176-180", "182-186"];
  const shoesizeOptions = [
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
  ];
  const clothingsizeOptions = [
    "42-44",
    "44-46",
    "46-48",
    "48-50",
    "50-52",
    "52-54",
    "54-56",
    "58-60",
  ];
  const headdressOptions = ["44", "46", "48", "50", "52", "54", "56", "58"];

  const isSaveDisabled = !(
    fio &&
    gender &&
    datebirthday &&
    phone &&
    shift &&
    post &&
    dateemployment &&
    height &&
    clothingsize &&
    shoesize &&
    headdress &&
    electro &&
    typeEmployment
  );

  const saveUpd = async () => {
    const hasPostChanged =
      initialEmpData && initialEmpData.mainPost.id !== post;
    const hasSizChanged =
      initialSizData &&
      (initialSizData.height !== height ||
        initialSizData.clothingsize !== clothingsize ||
        initialSizData.shoesize !== shoesize ||
        initialSizData.headdress !== headdress);

    if (hasPostChanged || hasSizChanged) {
      setChangedFields({
        post: hasPostChanged,
        siz: hasSizChanged,
      });
      setIsDateModalVisible(true);
      return;
    }
    try {
      const updatedSizData = {
        id: sizId,
        employee: { id: selectedEmployeeId },
        height: height,
        shoesize: shoesize,
        clothingsize: clothingsize,
        headdress: headdress,
        numelectrosecurity: electro,
      };

      const formattedDateBirthday = new Date(datebirthday).toISOString();
      const formattedDateEmpl = new Date(dateemployment).toISOString();
      const updatedEmpData = {
        fio: fio,
        gender: gender,
        datebirthday: formattedDateBirthday,
        phone: phone,
        shiftId: shift,
        postId: post,
        dateemployment: formattedDateEmpl,
        datesiz: formattedDateEmpl,
        dateChange: formattedDateEmpl,
        typeemployment: typeEmployment,
      };

      await updateEmpl(updatedEmpData);

      await updateSiz(updatedSizData);

      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Информация о сотруднике обновлена",
        isVisible: true,
      });

      
    } catch (error) {
      // Если произошла ошибка при обновлении данных SIZ, показываем сообщение об ошибке
      console.error("Ошибка при обновлении данных SIZ:", error);
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Произошла ошибка",
        isVisible: true,
      });
    }
  };

  const updateSiz = async (updatedSizData) => {
    try {
      const response = await axios2.put("/siz/update", updatedSizData);
      return response.data;
    } catch (error) {
      console.error("Error updating SIZ:", error);
      throw error;
    }
  };

  const updateEmpl = async (updatedEmpData) => {
    try {
      const response = await axios2.put(
        `/employees/${selectedEmployeeId}`,
        updatedEmpData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating:", error);
      throw error;
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
    closeModalUpd();
    onUpdateSuccess();
  };

  const handleDateChangeSubmit = async ({ postDateChange, sizDateChange }) => {
    const formattedPostDateChange = postDateChange
      ? new Date(postDateChange).toISOString()
      : undefined;
    const formattedSizDateChange = sizDateChange
      ? new Date(sizDateChange).toISOString()
      : undefined;

      console.log("postDateChange:", postDateChange);


    try {
      const updatedSizData = {
        id: sizId,
        employee: { id: selectedEmployeeId },
        height: height,
        shoesize: shoesize,
        clothingsize: clothingsize,
        headdress: headdress,
        dateChange: formattedSizDateChange,
      };

      const formattedDateBirthday = new Date(datebirthday).toISOString();
      const formattedDateEmpl = new Date(dateemployment).toISOString();
      const updatedEmpData = {
        fio: fio,
        gender: gender,
        datebirthday: formattedDateBirthday,
        phone: phone,
        shiftId: shift,
        postId: post,
        dateemployment: formattedDateEmpl,
        datesiz: formattedSizDateChange,
        dateChange: formattedPostDateChange,
      };

      console.log("дата", updatedEmpData.dateChange)
      await updateEmpl(updatedEmpData);
      await updateSiz(updatedSizData);

      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Информация о сотруднике обновлена",
        isVisible: true,
      });

      onUpdateSuccess();
      closeModalUpd();
      setIsDateModalVisible(false);
    } catch (error) {
      console.error("Ошибка при обновлении данных SIZ:", error);
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Произошла ошибка",
        isVisible: true,
      });
      setIsDateModalVisible(false);
    }
  };

  

  return (
    <div>
      <div className="modal">
        <div className="modal-content-empl">
          {employeeData && (
            <>
              <span className="close" onClick={closeModalUpd}>
                &times;
              </span>
              <div className="pers_info">
                <h3>Личная информация</h3>

                <label htmlFor="fio">ФИО</label>
                <input
                  type="text"
                  name="fio"
                  value={fio}
                  onChange={(e) => setFio(e.target.value)}
                ></input>

                <div className="gen">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="М"
                    checked={gender === "М"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label htmlFor="male">Мужской</label>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="Ж"
                    checked={gender === "Ж"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label htmlFor="female">Женский</label>
                </div>

                <label htmlFor="datebirthday">Дата рождения:</label>
                <input
                  type="date"
                  id="datebirthday"
                  name="datebirthday"
                  value={datebirthday}
                  onChange={(e) => setDateBirthday(e.target.value)}
                />

                <label htmlFor="phone" style={{ marginBottom: "10px" }}>
                  Номер телефона:
                </label>
                <InputMask
                  mask="+7 999 999 99-99"
                  maskChar="_"
                  placeholder="+7 ___ ___ __-__"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: "130px", marginBottom: "10px" }}
                />

                <label
                  htmlFor="shift-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  Смена:
                </label>
                <select
                  id="shift-dropdown"
                  value={shift}
                  style={{ width: "225px", marginBottom: "10px" }}
                  onChange={(e) => setShift(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите смену
                  </option>
                  {shiftOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.nameshift}
                    </option>
                  ))}
                </select>

                <label htmlFor="post-dropdown" style={{ marginBottom: "10px" }}>
                  Должность:
                </label>
                <select
                  id="post-dropdown"
                  value={post}
                  style={{ width: "185px", marginBottom: "10px" }}
                  onChange={(e) => setPost(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите должность
                  </option>
                  {postOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.namepost}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="electro-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  Группа электробезопасности:
                </label>
                <select
                  id="electro-dropdown"
                  value={electro}
                  style={{ width: "100%", marginBottom: "10px", marginTop: "10px"}}
                  onChange={(e) => setElectro(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите группу электробезопасности
                  </option>
                  <option value="1">
                    Первая
                  </option>
                  <option value="2">
                    Вторая
                  </option>
                </select>

                <label
                htmlFor="typeEmployment-dropdown"
              >
                Тип трудоустройства:
              </label>
              <select
                id="typeEmployment-dropdown"
                value={typeEmployment}
                style={{ width: "100%", marginBottom: "10px", marginTop: "10px"}}
                onChange={(e) => setTypeEmployment(e.target.value)}
              >
                <option value="" disabled>
                  Выберите тип трудоустройства
                </option>
                <option value="ТД" key="ТД" >
                  ТД
                </option>
                <option value="СЗ" key="СЗ">
                  СЗ
                </option>
                <option value="Другое" key="Другое">
                  Другое
                </option>
              </select>

                <label htmlFor="dateemployment">Дата трудоустройства:</label>
                <input
                  type="date"
                  id="dateemployment"
                  name="dateemployment"
                  value={dateemployment}
                  onChange={(e) => setDateEmployment(e.target.value)}
                />

                <h3>СИЗ</h3>

                <label
                  htmlFor="height-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  Рост:
                </label>
                <select
                  id="height-dropdown"
                  value={height}
                  style={{ width: "240px", marginBottom: "10px" }}
                  onChange={(e) => setHeight(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите рост
                  </option>
                  {heightOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="clothingsize-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  Размер одежды:
                </label>
                <select
                  id="clothingsize-dropdown"
                  value={clothingsize}
                  style={{ width: "142px", marginBottom: "10px" }}
                  onChange={(e) => setClothingSize(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите размер
                  </option>
                  {clothingsizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <label htmlFor="size-dropdown" style={{ marginBottom: "10px" }}>
                  Размер обуви:
                </label>
                <select
                  id="size-dropdown"
                  value={shoesize}
                  style={{ width: "158px", marginBottom: "10px" }}
                  onChange={(e) => setShoeSize(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите размер
                  </option>
                  {shoesizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="headdress-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  Размер головного убора:
                </label>
                <select
                  id="headdress-dropdown"
                  value={headdress}
                  style={{ width: "67px", marginBottom: "10px" }}
                  onChange={(e) => setHeaddress(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите размер
                  </option>
                  {headdressOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <button
                  className="add_send_emploee"
                  onClick={saveUpd}
                  disabled={isSaveDisabled}
                >
                  Сохранить
                </button>
              </div>
            </>
          )}
          {notification.isVisible && (
            <Notification
              status={notification.status}
              title={notification.title}
              subtitle={notification.subtitle}
              onClose={hideNotification}
              isVisible={notification.isVisible}
            />
          )}
        </div>
      </div>
      <DateChangeModal
        isVisible={isDateModalVisible}
        onClose={() => setIsDateModalVisible(false)}
        onSubmit={handleDateChangeSubmit}
        changedFields={changedFields}
      />
    </div>
  );
};

export default ModalUpd;